import React from "react";
import styled from "styled-components";

import { BlocksContent } from "@global";
import { Maybe, SanityFaqCategory } from "@graphql-types";
import { Container } from "@util/standard";
import { colorsRGB, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: Maybe<SanityFaqCategory>;
}

const FaqContainer = styled(Container)`
  flex-direction: column;
  padding: 150px 0 150px 12%;

  border-left: 1px solid ${colorsRGB.navy(0.4)};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-left: none;
    padding: 0;
    margin-top: 20px;
  }
`;

const AnswerWrapper = styled.div`
  width: 97%;
  margin: 0 0 35px 0;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: auto;
  }
`;

function QuestionsAndAnswers({ data }: Props) {
  if (!data) return null;

  return (
    <FaqContainer>
      {data.questions &&
        data.questions.map(question => {
          if (!question) return null;

          return (
            <AnswerWrapper key={question._key}>
              <h4 style={{ margin: "10px 0 0 0" }}>{question.question}</h4>
              <BlocksContent data={question.answer} />
            </AnswerWrapper>
          );
        })}
    </FaqContainer>
  );
}

export default QuestionsAndAnswers;
