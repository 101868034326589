import React, { useState } from "react";

import { Maybe, SanityFaqCategory } from "@graphql-types";
import { Container } from "@util/standard";

import { CategoryFilterBar } from "@global";
import QuestionsAndAnswers from "./questionsAndAnswers";
import { CategoryName } from "../../global/global.styles";

interface Props {
  data: Maybe<SanityFaqCategory>[];
}

function FaqsFilterBar({ data }: Props) {
  if (!data) return null;

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [mobileFilterVisible, setMobileFilterVisible] = useState(false);

  const categoryQuestions = data[currentCategoryIndex];

  const handleChange = (index: number) => () => {
    setMobileFilterVisible(false);
    setCurrentCategoryIndex(index);
  };

  return (
    <CategoryFilterBar
      mobileVisibility={mobileFilterVisible}
      setMobileVisibility={setMobileFilterVisible}
      filterResultChildren={<QuestionsAndAnswers data={categoryQuestions} />}
    >
      <Container width="100%" flexDirection="column">
        <Container flexDirection="column">
          {data.map((category, index) => {
            if (!category) return null;
            return (
              <CategoryName
                onClick={handleChange(index)}
                selected={index === currentCategoryIndex}
                key={category._key}
                isLast={index === data.length - 1}
              >
                {category.categoryName}
              </CategoryName>
            );
          })}
        </Container>
      </Container>
    </CategoryFilterBar>
  );
}

export default FaqsFilterBar;
