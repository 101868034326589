import React from "react";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import { Hero } from "@global";
import FaqsFilterBar from "@components/faqs/faqsFilterBar";

interface Props extends PageProps {
  data: Query;
}

export default function FaqsPage({ data }: Props) {
  if (data.sanityFaqsPage == null) {
    return null;
  }

  const { seo, hero, faqCategories } = data.sanityFaqsPage;

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        <Hero data={hero} />
        {faqCategories && <FaqsFilterBar data={faqCategories} />}
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityFaqsPage {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
      faqCategories {
        categoryName
        _key
        _type
        questions {
          _key
          question
          answer {
            ...sanityBlockContent
          }
        }
      }
    }
  }
`;
